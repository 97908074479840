<template>
  <div class="common_tree_handle">
    <el-header
      style="text-align: right; font-size: 12px;height:auto;min-height: 46px;background-color: #ffffff;padding:0 12px;display:flex;align-items:center;"
      ref="elHeader" v-if="titleShow">
      <el-row style="display:flex;align-items:center;width: 100%;line-height: 45px;border-bottom: 1px solid #cccccc;">
        <el-col span="18" style="float: left;display:flex;align-items:center;">
          <el-tooltip effect="dark" :content="treeTitle || this.$t('cip.cmn.title.typeTitle')" placement="top-start">
            <label
              style="font-size: 14px; text-align: left;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{
                treeTitle === "" ? $t('cip.cmn.title.typeTitle') : treeTitle
              }}</label>
          </el-tooltip>
        </el-col>
      </el-row>
    </el-header>
    <div style="position: relative">
      <avue-tree
        class="avueTree"
        v-loading="treeLoading"
        v-model="treeForm"
        :data="treeData"
        :option="treeOptions"
        :defaultExpandAll="defaultExpandAll"
        @node-click="treeNodeClick"
        @save="saveNode"
        @update="updateNode"
        @rowAdd="rowAdd"
        ref="avueTree"
      >
        <!--       @del="delNode" -->
<!--        <template slot-scope="scope" slot="node">-->
<!--          <el-tooltip :content="node.label" placement="right">-->
<!--            <span>{{ node.label }}</span>-->
<!--          </el-tooltip>-->

<!--        </template>-->

        <span class="el-tree-node__label" slot-scope="{ node, data }">
          <el-tooltip :content="node.label" placement="bottom">
              <span>
                {{ (node || {}).label }}
              </span>
          </el-tooltip>
        </span>
      </avue-tree>
      <!--    <el-button type="primary" icon="el-icon-more" class="moreBtn"></el-button>-->
      <el-popover
        placement="right"
        width="80"
        v-if="popoverShow"
        trigger="hover">
        <el-button type="text" size="mini" style="font-size: 14px" @click="openAll"
                   v-if="defaultExpandAll == false && expandShow">展开全部
        </el-button>
        <el-button type="text" size="mini" style="font-size: 14px" @click="openAll"
                   v-if="defaultExpandAll == true && expandShow">收缩全部
        </el-button>
        <el-button type="text" size="mini" style="margin: 0;font-size: 14px" v-if="isInclude == false"
                   @click="includeDown">包含下级
        </el-button>
        <el-button type="text" size="mini" style="margin: 0;font-size: 14px" v-if="isInclude == true"
                   @click="includeDown">不包含下级
        </el-button>
        <el-button slot="reference" type="primary" icon="el-icon-more" class="moreBtn"></el-button>
      </el-popover>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {region} from "@/api/system/region";

export default {
  props: {
    treeForm: {
      type: Object,
      default: () => ({})
    },
    treeOptions: {
      type: Object,
      default: () => ({})
    },
    treeLoading:{
      type: Boolean,
      default: true,
    },
    expandShow: {
      type: Boolean,
      default: false,
    },
    popoverShow: {
      type: Boolean,
      default: false,
    },
    treeData: {
      type: Array,
      default: []
    },
    titleShow: {
      type: Boolean,
      default: true
    },
    treeTitle: {
      type: String,
      default: ""
    },
  },
  components: {},
  data() {
    return {
      defaultExpandAll: false,
      isInclude: false
    };
  },
  computed: {
    ...mapGetters(["permission"]),
  },
  mounted() {
    this.$refs.avueTree.$children[0].$refs.input.placeholder = this.$t('cip.cmn.msg.warning.filterateWarning');
    let height = document.documentElement.clientHeight;
    height = this.$refs.avueTree.$el.offsetHeight;
    // document.getElementsByClassName("el-tree").item(0).style.height=height+"px"
    document.getElementsByClassName("avue-tree").item(0).style.background = "#FFFFFF";
    document.getElementsByClassName("el-tree").item(0).style.background = "#FFFFFF";
    this.treeOptions.iconClass = "el-icon-folder-opened";
    if (this.popoverShow) {
      this.$refs.avueTree.$children[0].$refs.input.style.width = 'calc(100% - 34px)'
    }
  },
  methods: {
    openAll() {
      this.defaultExpandAll = !this.defaultExpandAll;
    },
    includeDown() {
      this.isInclude = !this.isInclude
      this.$emit("include-down", this.isInclude);
    },
    menuAdd(data) {
      this.$emit("tree-menu-add", data);
    },
    menuEdit(data) {
      this.$emit("tree-menu-edit", data);
    },
    menuDel(data) {
      this.$emit("tree-node-del", data);
    },

    rowAdd() {
      console.log('ts');
    },
    treeNodeClick(node) {
      // this.isInclude = false;
      this.$emit("tree-node-click", node);
    },
    saveNode(parent, data, done, loading) {
      this.$emit("tree-node-save", parent, data, done, loading);
    },

    delNode(data, done) {
      console.log(data, done, 'tt');
      // this.$emit("tree-node-del", data, done);
    },
    updateNode(parent, data, done, loading) {
      this.$emit("tree-node-update", parent, data, done, loading);
    },
    /*treeLoad(treeNode, resolve){
      this.$emit('tree-load-cus', treeNode, resolve)
    },*/
  }
};
</script>

<style scoped lang="scss">
::v-deep .avueTree .avue-tree__filter .el-input .el-input__inner {
  height: 28px;
  line-height: 28px;
  font-size: 12px;
}

.avue-tree {
  padding: 14px 12px;
}

::v-deep .el-tree {
  /* height:100% !important; */
  height: calc(100vh - 237px) !important;
  overflow-y: scroll;
}

.common_tree_handle {
  width: 279px;
  margin-right: 0 !important;
  border-right: 1px solid #cccccc;
}

.moreBtn {
  position: absolute;
  top: 14px;
  right: 9px;
  transform: rotate(90deg);
  padding: 7px;
}
</style>
